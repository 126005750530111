import { template as template_736b0f6a449b45beb0e141ea56e1b7d3 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import bodyClass from "discourse/helpers/body-class";
const AddCategoryTagClasses = template_736b0f6a449b45beb0e141ea56e1b7d3(`
  {{#if @category}}
    {{bodyClass "category" (concat "category-" @category.fullSlug)}}
  {{/if}}

  {{#each @tags as |tag|}}
    {{bodyClass (concat "tag-" tag)}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddCategoryTagClasses;
